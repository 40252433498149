.copyright {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.module-container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

/* Footer navigation buttons */
.module-nav {
  display: flex;
}

.module-nav button {
  margin: 0.5rem 0.5rem 0.5rem 0rem;
}

.video-player {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px;
    border-color: black;
    border-style: solid;
}

.module-text li {
    padding-left: 1rem;
}

/* For mobile view, adjust the height of the main content */
@media (max-width: 768px) {
    .spacer {
      height: 60px;
    }
  }

  .content-toggle {
    background-color: rgb(204,229,254);
    font-size: 2rem;
    position: fixed;
    top: 20px;
    right: 30px;
    color: black;
    height: 2rem;
    width: 2rem !important;
    /* padding: 0px .9rem .1rem .9rem; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 9999;
  }

  /* ModuleEnd CSS */


.module-end {
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.module-end h1 {
  font-size: 2rem;
  font-weight: bold;
}

.module-end p {
  font-size: 1.25rem;
  margin: 10px 0;
}

.image-row {
  margin: 20px 0;
}

.full-width-image {
  width: 100%;
  max-width: none; /* Remove the max-width constraint */
}

.rating-container {
  margin-top: 10px; /* Reduce space above the rating system */
}

.satisfaction-label {
  display: block;
  margin-bottom: 5px; /* Reduce space below the satisfaction label */
}

.exclude-from-pdf {
  display: flex;
  justify-content: center;
  margin-top: 40px; /* Add more space above the button */
}

.continue-button {
  margin-top: 20px; /* Ensure space between the rating and the button */
}

.download-icon {
  font-size: 1.5rem; /* Adjust the icon size if needed */
  margin-right: 10px; /* Space between the icon and the text */
}

.message-text {
  text-align: left; /* Ensure the text is aligned to the left */
}

/* New styles for the download message box */
.course-end-message {
  background-color: #e0f7fa; /* Light blue background */
  padding: 10px;
  border-radius: 5px;
  margin: 20px 0;
  font-size: 1rem;
  cursor: pointer; /* Make the cursor a pointer to indicate clickability */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
}