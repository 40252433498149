/* src/App.css */

.full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.admin-view {
  padding-left: 0px;
  padding-right: 0px;
  height: 100vh; /* Set height to full viewport height */
  overflow-y: auto !important; /* Enable vertical scrolling */
  box-sizing: border-box; /* Include padding in height calculation */
}

.admin-content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.admin-content hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .hide-in-mobile {
    display: none;
  }

  .container-fluid {
    flex-direction: column;
  }

  .sidebar {
    display: none;
  }

  .content {
    height: calc(100vh - 80px); /* Adjust height to account for footer */
    width: 100%;
    overflow-y: auto; /* Ensure content is scrollable */
    overflow-x: hidden;
    position: fixed;
    top: 0;
  }
}

.sidebar {
  height: 100%;
  position: fixed;
}

.sidebar-sticky {
  position: sticky;
  top: 0;
  padding-top: 1.5rem;
}

.branding {
  margin-bottom: 1.5rem;
}

.ml-sm-auto {
  margin-left: auto;
}

.fixed-bottom {
  padding: 10px;
  height: 60px;
}

.footer-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px; /* Match the height of the footer */
  padding: 0 10px;
}

.footer-nav button {
  flex: 1;
  margin: 0 5px;
}

.footer-nav-previous {
  background-color: rgb(212,237,217);
  color: black;
  border: none;
  border-radius: 0.375rem;;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.footer-nav-current {
  background-color: rgb(204,229,254);
  color: black;
  border: none;
  border-radius: 0.375rem;;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.footer-nav-next {
  background-color: white;
  color: black;
  border: none;
  border-radius: 0.375rem;;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.module-item.completed {
  background-color: #d4edda;
}

.module-item.current {
  background-color: #cce5ff;
}

.module-item:disabled {
  background-color: #f8d7da;
  color: #6c757d;
}
