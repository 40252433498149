.info-box {
  background-color: lightyellow;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.info-box li p {
  margin-bottom: 0rem;
}

.osioImage {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.image-stack {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.youtube-player {
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
