.feedback-component {
  max-width: 600px; /* Adjust this value to change the width of the form */
  margin: 20px auto; /* Center the form and add top margin */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.feedback-component h3 {
  margin-bottom: 20px;
}

.feedback-component .form-group {
  margin-bottom: 20px;
}

.feedback-component .form-label {
  font-weight: bold;
}

.feedback-component .form-control {
  border-radius: 5px;
}

.feedback-component .rating-container {
  text-align: center;
  margin-bottom: 20px;
}

.feedback-component button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #007bff;
  border: none;
  color: #fff;
  font-size: 1rem;
  transition: background 0.3s;
  margin-top: 20px;
}

.feedback-component button:hover {
  background-color: #0056b3;
}
